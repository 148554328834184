/* src/components/SplashScreen.css */
.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
}

.splash-logo {
  width: 100px;
  height: auto;
  animation: grow-smash 3s ease-in-out forwards;
}

@keyframes grow-smash {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(3);
    opacity: 1;
  }
  80% {
    transform: scale(3);
    opacity: 1;
    filter: brightness(1);
  }
  90% {
    transform: scale(3.2);
    opacity: 0.5;
    filter: brightness(1.5);
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
    filter: brightness(2);
  }
}
