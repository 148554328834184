.toggle-switch {
  display: flex;
  align-items: center;
  font-size: 0.875rem; /* Smaller font size */
}

.toggle-container {
  display: flex;
  justify-content: flex-end; /* Move toggle switches to the right */
  align-items:flex-end;
}

.toggle-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.toggle-switch label {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-indent: -9999px;
  width: 34px; /* Smaller width */
  height: 20px; /* Smaller height */
  background: #c6c6c6;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.1s;
}

.toggle-switch label::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px; /* Smaller toggle */
  height: 16px; /* Smaller toggle */
  border-radius: 90px;
  transition: 0.1s;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.toggle-switch input[type="checkbox"]:checked + label {
  background: #4fbe79;
}

.toggle-switch input[type="checkbox"]:checked + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggle-switch label:active::after {
  width: 20px; /* Slightly bigger on active */
}
