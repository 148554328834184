/* Header.css */

.header {
    text-align: left;
    margin: 20px 0;
  }
  
  .header-logo {
    width: 20%; /* Adjust the logo size */
  }
