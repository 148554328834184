.current-weather {
  margin: 20px;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.current-weather-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.current-weather-card h2 {
  margin-bottom: 10px;
}

.current-weather-card img {
  width: 100px;
  height: 100px;
}

.current-weather-details {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.current-weather-details div {
  margin: 10px;
  text-align: center;
}

.current-weather-details span {
  display: block;
  font-size: 1.2em;
}

.weather-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .current-weather-details {
    flex-direction: column;
    align-items: center;
  }

  .current-weather-card img {
    width: 80px;
    height: 80px;
  }

  .weather-icon {
    width: 30px;
    height: 30px;
  }
}

body.dark .current-weather {
  background-color: #2a2a2a;
  color: #f2f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

body.dark .current-weather-card {
  background-color: #333;
  color: #f2f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
