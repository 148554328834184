/* Styles for the search bar container */
.search-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative; /* Ensure suggestions are positioned relative to the search bar */
  width: 100%; /* Make the search bar take up the full width of its parent */
  max-width:max-content; /* Set a max-width to make it wider */
}

/* Styles for the input field */
.search-bar input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure the input field takes up the full width */
  transition: border-color 0.3s ease;
  box-sizing: border-box; /* Ensure padding is included in the element's total width */
}

/* Styles for the search button */
.search-bar button {
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for the search button */
.search-bar button:hover {
  background-color: #0056b3;
}

/* Styles for error state */
.search-error {
  border-color: red !important;
}

/* Animation for shaking effect */
.search-bar.shake input.search-error {
  animation: shake 0.5s;
}

/* Keyframes for shaking animation */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

/* Media query for larger screens */
@media (min-width: 600px) {
  .search-bar {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .search-bar button {
    margin-left: 10px;
    margin-top: 0;
  }
}

/* Styles for the suggestions container */
.react-autosuggest__suggestions-container {
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  z-index: 2;
  width: 100%; /* Ensure the width matches the input */
  box-sizing: border-box; /* Include padding in the element's total width */
}

/* Styles for individual suggestions */
.react-autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
  white-space: pre-wrap; /* Ensure the text wraps */
  word-wrap: break-word; /* Break long words to avoid overflow */
}

/* Styles for highlighted suggestions */
.react-autosuggest__suggestion--highlighted {
  background: #ddd;
}

/* Styles for suggestion content */
.suggestion-content {
  white-space: pre-wrap; /* Ensure the text wraps */
  word-wrap: break-word; /* Break long words to avoid overflow */
}

/* Dark mode adjustments */
body.dark .search-bar input {
  background-color: #333;
  color: #f2f2f2;
  border: 1px solid #444;
}

body.dark .search-bar button {
  background-color: #444;
}

body.dark .search-bar button:hover {
  background-color: #666;
}

body.dark .react-autosuggest__suggestions-container {
  background-color: #333;
  border: 1px solid #444;
}

body.dark .react-autosuggest__suggestion {
  color: #f2f2f2;
}

body.dark .react-autosuggest__suggestion--highlighted {
  background-color: #444;
}
