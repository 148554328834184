body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #f0f0f0;
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.landing-header {
  margin-bottom: 20px;
  animation: fadeInDown 1s ease-in-out;
}

.landing-logo {
  width: 200px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.landing-logo:hover {
  transform: scale(1.1);
}

.landing-main {
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInUp 1s ease-in-out;
}

.landing-main h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: justify;
}

.landing-main p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #666;
  text-align: justify;
}

.project-inspiration,
.landing-features {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: justify; /* Justify text in Key Features and Inspiration sections */
}

.project-inspiration h2,
.landing-features h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0b0b0c;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 20px;
}

.feature {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 calc(33.333% - 2rem);
  max-width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.7); /* Blue glow effect */
}

.feature-icon {
  width: 64px;
  height: auto;
  margin-bottom: 1rem;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

.developer-info {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: justify; /* Justify text in Developer Info section */
}

.developer-info h2 {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #333;
}

.developer-info p {
  font-size: 1.1rem;
  color: #666;
}

.welcome-section,
.inspiration-section {
  text-align: justify; /* Justify text in Welcome and Inspiration sections */
}

.landing-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.github-link {
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.github-link:hover {
  color: #000;
  transform: scale(1.05);
}

.github-logo {
  width: 30px;
  height: auto;
  margin-right: 8px;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
