body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.5s, color 0.5s;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    transition: background-color 0.5s, box-shadow 0.5s;
}

.text-center {
    text-align: center;
}

.margin-auto {
    margin: auto;
}

/* Dark mode */
body.dark {
    background-color: #1e1e1e;
    color: #f2f2f2;
}

body.dark .container {
    background-color: #2a2a2a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Chart and table styling */
.trend-chart {
    padding: 20px;
    background-color: var(--background-color, #fff);
    color: var(--text-color, #000);
    transition: background-color 0.5s, color 0.5s;
    animation: fadeIn 1s ease-in;
}

.chart-container {
    margin: 20px 0;
    animation: fadeIn 1s ease-in;
}

canvas {
    background-color: var(--background-color, #fff);
    transition: background-color 0.5s;
}

.detailed-forecast {
    padding: 20px;
}

.forecast-header {
    position: sticky;
    top: 0;
    background-color: inherit;
    padding: 10px 0;
    z-index: 10;
     transition: background-color 0.5s, color 0.5s;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    transition: background-color 0.5s, box-shadow 0.5s;
}

.text-center {
    text-align: center;
}

.margin-auto {
    margin: auto;
}

/* Dark mode */
body.dark {
    background-color: #1e1e1e;
    color: #f2f2f2;
}

body.dark .container {
    background-color: #2a2a2a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Chart and table styling */
.trend-chart {
    padding: 20px;
    background-color: var(--background-color, #fff);
    color: var(--text-color, #000);
    transition: background-color 0.5s, color 0.5s;
    animation: fadeIn 1s ease-in;
}

.chart-container {
    margin: 20px 0;
    animation: fadeIn 1s ease-in;
}

canvas {
    background-color: var(--background-color, #fff);
    transition: background-color 0.5s;
}

.detailed-forecast {
    padding: 20px;
}

.forecast-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.forecast-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--button-background, #007bff);
    color: var(--button-color, #fff);
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

.forecast-buttons button:hover {
    background-color: var(--button-hover-background, #0056b3);
    transform: scale(1.05);
}

.day-forecast {
    margin-bottom: 20px;
}

.table-container {
    overflow-y: scroll;
    max-height: 600px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--border-color, #ddd);
}

tr:nth-child(even) {
    background-color: var(--table-row-alt-background, #f9f9f9);
    transition: background-color 0.5s;
}

/* Dark mode for charts and tables */
body.dark .trend-chart {
    background-color: var(--background-color, #2a2a2a);
    color: var(--text-color, #f2f2f2);
}

body.dark canvas {
    background-color: var(--background-color, #2a2a2a);
}

body.dark table th, body.dark table td {
    border-bottom: 1px solid var(--border-color, #444);
}

body.dark tr:nth-child(even) {
    background-color: var(--table-row-alt-background, #444);
}

/* Responsive design */
@media (max-width: 768px) {
    .forecast-buttons {
        flex-direction: column;
        align-items: center;
    }

    .forecast-buttons button {
        margin: 5px 0;
    }
}
