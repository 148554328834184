.App {
  text-align: center;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust this value to align with header logo */
}

.toggle-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.checkbox-container {
  margin-left: 10px;
}

.loading {
  font-size: 1.2em;
  color: #888;
}

.error-message {
  color: red;
  font-size: 1.2em;
  margin-top: 20px;
}

@media (min-width: 600px) {
  .search-container {
    flex-direction: row;
  }
  .checkbox-container {
    margin-left: 10px;
    margin-top: 0;
  }
}

body {
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

body.dark {
  background-color: #1e1e1e; /* Darker background */
  color: #f2f2f2;
}

body.dark table {
  color: #f2f2f2;
}

body.dark th,
body.dark td {
  color: #f2f2f2;
  border-color: #444;
}

body.dark th {
  background-color: #333;
}

body.dark td {
  background-color: #2a2a2a;
}

body.light {
  background-color: #ffffff;
  color: #212529; /* Darker text for contrast */
}

.theme-toggle button {
  padding: 8px 16px;
  background-color: #212529; /* Dark button for contrast */
  color: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.theme-toggle button:hover {
  background-color: #343a40; /* Slightly darker on hover */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0; /* Add margin to avoid sticking to other elements */
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center-align all text */
  width: 14.28%; /* 7 columns, each taking up approximately 1/7 of the table width */
}

/* Center table headings */
table thead th {
  text-align: center;
}

/* Toggle Switch Styles */
.toggle-switch {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch .slider {
  position: relative;
  width: 34px;
  height: 14px;
  background-color: #ccc;
  border-radius: 34px;
  margin-left: 8px;
  transition: background-color 0.2s;
}

.toggle-switch .slider::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  top: -3px;
  left: -3px;
  transition: transform 0.2s;
}

.toggle-switch input[type="checkbox"]:checked + .slider {
  background-color: #2196F3;
}

.toggle-switch input[type="checkbox"]:checked + .slider::before {
  transform: translateX(20px);
}

/* Media queries for mobile responsiveness */
@media (max-width: 600px) {
  .search-container {
    flex-direction: column;
  }

  .toggle-container {
    flex-direction: column;
  }

  .toggle-switch {
    margin-bottom: 10px;
  }

  .checkbox-container {
    margin-left: 0;
    margin-top: 10px;
  }

  .summary-forecast table,
  .detailed-forecast table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .summary-forecast th,
  .summary-forecast td,
  .detailed-forecast th,
  .detailed-forecast td {
    font-size: 14px;
    padding: 8px;
  }
}

.summary-forecast {
  margin: 20px;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: inherit; /* Inherit text color from the parent for both dark and light modes */
}

.forecast-container {
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.forecast-slot {
  flex: 0 0 auto;
  margin: 10px;
  padding: 20px;
  background-color: #e0f7fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.forecast-slot h3 {
  margin-bottom: 10px;
  color: #00796b;
}

.weather-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-info img {
  margin-right: 10px;
}

.forecast-slot p {
  margin: 5px 0;
}

@media (max-width: 600px) {
  .forecast-container {
    flex-direction: column;
  }

  .forecast-slot {
    margin: 10px auto;
    width: 80%;
  }

  .summary-forecast table,
  .detailed-forecast table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .summary-forecast th,
  .summary-forecast td,
  .detailed-forecast th,
  .detailed-forecast td {
    padding: 8px;
    font-size: 14px;
  }
}

.summary-forecast table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0; /* Add margin to avoid sticking to other elements */
}

.summary-forecast th,
.summary-forecast td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center-align all text */
  width: 14.28%; /* 7 columns, each taking up approximately 1/7 of the table width */
}

/* Center table headings */
.summary-forecast thead th {
  text-align: center;
}

/* Dark mode specific styles */
body.dark .summary-forecast {
  background-color: #2a2a2a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

body.dark .forecast-slot {
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

body.dark .summary-forecast th,
body.dark .summary-forecast td {
  color: #f2f2f2;
  border-color: #444;
}

body.dark .summary-forecast th {
  background-color: #444;
}

body.dark .summary-forecast td {
  background-color: #3a3a3a;
}
